<template>
  <view-item title="应收款统计">
    <template #operation>
      <button-ajax type="primary" :method="operation.detail.click" v-if="tabs.active === 'statistics'">查看总计</button-ajax>
      <button-ajax type="primary" :method="operation.buyDetail.click" v-if="tabs.active === 'indetail'">查看总计</button-ajax>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="应收款统计" name="statistics">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="CUSRCVFD"
                        :height="height"
                        :data="statistics.table.data"
                        :method="statistics.table.get"
                        :loading="statistics.table.loading"
                        pagination
                        :paging="statistics.table.paging"
                        :config="statistics.table.config"
                      >
                        <template #OPERATION="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'], $index: number }">
                          <en-button type="primary" link @click="operation.exports.click()">导出明细</en-button>
                        </template>
                        <template #CUSTOMER_NAME="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'] }">
                          <en-button type="primary" link @click="operation.name.click(row)">{{ row.customer?.name }}</en-button></template
                        >
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="挂账统计" name="indetail">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="RCVBCRFD"
                        :height="height"
                        :data="sale.table.data"
                        :method="sale.table.get"
                        :loading="sale.table.loading"
                        pagination
                        :paging="sale.table.paging"
                        :config="sale.table.config"
                      ></table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
    <en-drawer v-model="detail.visible" title="查看总计" size="20%">
      <div v-for="item in detail.table.data" class="flex flex-col gap-7">
        <div class="flex flex-col gap-4">
          <div style="color: #4c58d9ff">期初金额:{{ formatMoney(Math.abs(item.initialAmount)) }}</div>
          <div style="color: #4c58d9ff">本期应收金额:{{ formatMoney(Math.abs(item.currentAmount)) }}</div>
          <div style="color: #4c58d9ff">本期已收金额:{{ formatMoney(Math.abs(item.currentDiscountAmount)) }}</div>
          <div style="color: #4c58d9ff">期末应收金额:{{ formatMoney(Math.abs(item.currentBalanceAmount)) }}</div>
        </div>
      </div>
      <template #footer>
        <en-button type="primary" @click="detail.footer.cancel.click">取消</en-button>
        <en-button type="primary" @click="detail.footer.confirm.click">确定</en-button>
      </template>
    </en-drawer>
    <en-drawer v-model="buyDetail.visible" title="查看总计" size="20%">
      <div v-for="item in buyDetail.table.data" class="flex flex-col gap-7">
        <div class="flex flex-col gap-4">
          <div style="color: #4c58d9ff">挂账金额:{{ formatMoney(Math.abs(item.creditAmount)) }}</div>
          <div style="color: #4c58d9ff">已收款:{{ formatMoney(Math.abs(item.writeOffAmount)) }}</div>
          <div style="color: #4c58d9ff">未收款:{{ formatMoney(Math.abs(item.remainingAmount)) }}</div>
          <div style="color: #4c58d9ff">优惠减免:{{ formatMoney(Math.abs(item.badDebt)) }}</div>
        </div>
      </div>
      <template #footer>
        <en-button type="primary" @click="buyDetail.footer.cancel.click">取消</en-button>
        <en-button type="primary" @click="buyDetail.footer.confirm.click">确定</en-button>
      </template>
    </en-drawer>
    <en-dialog v-model="inDetail.visible" title="应收款统计明细">
      <en-table :data="inDetail.table.data">
        <en-table-column label="往来单位" prop="customer.name"></en-table-column>
        <en-table-column label="业务单号" prop="serialNo"></en-table-column>
        <en-table-column label="单据类型" prop="documentType.message"></en-table-column>
        <en-table-column label="日期">
          <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'] }">
            {{ formatDate(row.datetime) }}
          </template>
        </en-table-column>
        <en-table-column label="产生金额" prop="amount">
          <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'] }">
            {{ formatMoney(row.amount) }}
          </template>
        </en-table-column>
      </en-table></en-dialog
    >
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          detail: {
            async click() {
              this.detail.table.get()
              this.detail.visible = true
            }
          },
          buyDetail: {
            async click() {
              this.buyDetail.table.get()
              this.buyDetail.visible = true
            }
          },
          name: {
            click(row:any) {
              this.inDetail.form.data.customerId = row?.customer?.id
              this.inDetail.visible = true
              this.inDetail.table.get()
            }
          },
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/export/account/:type',
                params(params) {
                  params.paths = ['RCB']
                }
              },
              buyget: {
                action: 'GET /enocloud/settlement/receivable/credit/export'
              }
            },
            async click() {
              switch (this.tabs.active) {
                case 'statistics':
                  this.operation.export.get()
                  this.store.openDownload()
                  break
                case 'indetail':
                  this.operation.export.buyget()
                  this.store.openDownload()
                  break
              }
            }
          },
          exports: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/export/account/:type/details',
                params(params) {
                  params.paths = ['RCB']
                }
              }
            },
            async click() {
              await this.operation.exports.get()
              this.store.openDownload()
            }
          }
        }
      },

      tabs: {
        active: 'statistics'
      },
      inDetail: {
        visible: false,
        children: {
          form: {
            data: {
              customerId: '',
              startDate: '',
              endDate: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/account/:type/details',
                data: 'array',
                loading: true,
                params(params) {
                  params.paths = ['RCB']
                  params.payload = this.inDetail.form.data
                }
              }
            }
          }
        }
      },

      detail: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/account/summary/:type',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = this.statistics.table.$ajaxParams.paths
                  params.payload=this.statistics.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              click() {
                this.detail.visible = false
              }
            }
          }
        }
      },
      buyDetail: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/receivable/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = this.sale.table.$ajaxParams.paths
                  params.payload=this.sale.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.buyDetail.visible = false
              }
            },
            confirm: {
              click() {
                this.buyDetail.visible = false
              }
            }
          }
        }
      },
      statistics: {
        children: {
          form: {
            data: {
              startDate: '',
              endDate: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/account/:type',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['RCB']
                  params.payload = this.statistics.form.data
                }
              }
            },
            config: {
              CUSTOMER_NAME: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'customerId',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/customer',
                        params: (params, value) => (params.payload = { name: value, rsexp: 'id,name', pagingEnabled: false })
                      },
                      remote: true,
                      props: { label: 'name', value: 'id' }
                    }
                  }
                }
              },
              CUSTOMER_TYPE: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'unitType',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/lookup/:lookupType',
                        params: (params) => (params.paths = ['CUSTTYPE'])
                      },
                      props: { label: 'message', value: 'code' },
                      remote: true
                    }
                  }
                }
              },
              CUSTOMER_CONTACT_NAME: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'contactName'
                  }
                }
              },
              CELLPHONE: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'contactCellphone'
                  }
                }
              }
            }
          }
        }
      },
      sale: {
        children: {
          form: {
            data: {
              quickSearch: '',
              credited: 'Y',
              branchIds: []
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/receivable/query',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.sale.form.data
                }
              }
            },
            config: {
              SERVICE_SETTLEMENT_DATETIME: {
                header: { filter: { type: 'date', field: ['startSettlementDatetime', 'endSettlementDatetime'], props: { type: 'daterange' } } }
              },
              CREDIT_DATETIME: {
                header: { filter: { type: 'date', field: ['creditStartDate', 'creditEndDate'], props: { type: 'daterange' } } }
              },
              CREDIT_DUE_DATETIME: {
                header: {
                  filter: {
                    type: 'date',
                    field: ['creditDueStartDate', 'creditDueEndDate'],
                    props: { type: 'daterange' }
                  }
                }
              },
              PREPARED_BY: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'settlementByName'
                  }
                }
              },
              CREDIT_COMMENT: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'creditComment'
                  }
                }
              },
              CREDIT_BY: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'creditBy'
                  }
                }
              },
              STATUS: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'statusCode',
                    props: {
                      options: [
                        { message: '已结清', code: 'F' },
                        { message: '已挂账', code: 'C' }
                      ],
                      props: { label: 'message', value: 'code' }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.statistics.table.get()
    this.sale.table.get()
  }
})
</script>
